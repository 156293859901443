import * as WS from 'isomorphic-ws';
import { nanoid } from './util';
import { WebSocket } from './websocket';
import { Signal as IONSignal, Trickle } from '@playback-sports/ion-sdk-js';
import {
    BaseSignal,
    IdentifiedSignal,
    SignalPeerAnswer,
    SignalPeerClose,
    SignalPeerCreate,
    SignalPeerJoin,
    SignalPeerOffer,
    SignalPeerTrickle,
    SignalType,
} from './signal';
import { JoinConfig } from './typings';

export class PeerSignal implements IONSignal {
    private _socket: WebSocket;
    private _peerID: string;
    private _jc: JoinConfig;
    onnegotiate?: (jsep: RTCSessionDescriptionInit) => void;
    ontrickle?: (trickle: Trickle) => void;

    constructor(
        socket: WebSocket,
        peerID: string,
        jc: JoinConfig = { noPublish: false, noSubscribe: false }
    ) {
        this._socket = socket;
        this._peerID = peerID;
        this._jc = jc;
        this._socket.addMessageListener(this._handleSocketMessage.bind(this));
    }

    private _handleSocketMessage(event: WS.MessageEvent) {
        const s: BaseSignal = JSON.parse(<string>event.data);
        if (s.type === SignalType.PeerOffer) {
            const so = <SignalPeerOffer>s;
            if (so.peerID !== this._peerID) {
                // console.log('peer ids for offer dont match', so.peerID, this._peerID);
                return;
            }

            if (this.onnegotiate) this.onnegotiate(so.offer);
        } else if (s.type === SignalType.PeerTrickle) {
            const st = <SignalPeerTrickle>s;
            if (st.peerID !== this._peerID) {
                // console.log('peer ids for trickle dont match', st.peerID, this._peerID);
                return;
            }

            const trickle: Trickle = {
                candidate: st.candidate,
                target: st.target,
            };
            if (this.ontrickle) this.ontrickle(trickle);
        }
    }

    private async _createPeer() {
        const id = nanoid();
        const s: SignalPeerCreate = {
            type: SignalType.PeerCreate,
            id: id,
            peerID: this._peerID,
        };
        return new Promise<void>((resolve, reject) => {
            this._socket
                .syncSend(s)
                .then((_: IdentifiedSignal) => {
                    resolve();
                })
                .catch(reject);
        });
    }

    private async _closePeer() {
        const id = nanoid();
        const s: SignalPeerClose = {
            type: SignalType.PeerClose,
            id: id,
            peerID: this._peerID,
        };

        return new Promise<void>((resolve, reject) => {
            this._socket
                .syncSend(s)
                .then((_: IdentifiedSignal) => {
                    resolve();
                })
                .catch(reject);
        });
    }

    set peerID(peerID: string) {
        this._peerID = peerID;
    }

    async join(
        sid: string,
        uid: string | null,
        offer: RTCSessionDescriptionInit
    ): Promise<RTCSessionDescriptionInit> {
        await this._createPeer();

        return new Promise<RTCSessionDescriptionInit>((resolve, reject) => {
            const id = nanoid();
            const s: SignalPeerJoin = {
                type: SignalType.PeerJoin,
                id: id,
                peerID: this._peerID,
                sid: sid,
                offer: offer,
                config: this._jc,
            };

            this._socket
                .syncSend(s)
                .then((s: IdentifiedSignal) => {
                    resolve((<SignalPeerAnswer>s).offer);
                })
                .catch(reject);
        });
    }

    trickle(trickle: Trickle) {
        const s: SignalPeerTrickle = {
            type: SignalType.PeerTrickle,
            peerID: this._peerID,
            target: trickle.target,
            candidate: trickle.candidate,
        };
        this._socket.send(s);
    }

    async offer(offer: RTCSessionDescriptionInit): Promise<RTCSessionDescriptionInit> {
        const id = nanoid();
        const s: SignalPeerOffer = {
            type: SignalType.PeerOffer,
            id: id,
            peerID: this._peerID,
            offer: offer,
        };

        return new Promise<RTCSessionDescriptionInit>((resolve, reject) => {
            this._socket
                .syncSend(s)
                .then((s: IdentifiedSignal) => {
                    resolve((<SignalPeerAnswer>s).offer);
                })
                .catch(reject);
        });
    }

    answer(answer: RTCSessionDescriptionInit) {
        const s: SignalPeerAnswer = {
            type: SignalType.PeerAnswer,
            peerID: this._peerID,
            offer: answer,
        };
        this._socket.send(s);
    }

    async close() {
        this._socket.removeMessageListener(this._handleSocketMessage.bind(this));
        await this._closePeer();
    }
}
